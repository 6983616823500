import React from 'react';
//import Messages from './MessagesWiget';
import Calendar from './CalendarWidget';
//import Gigs from 'GigsWiget';
import {useUser} from './UserContext';
import flexygig from '../assets/images/gigs.ico';

import '../styles/UserDashboard.css';

const UserDashboard = () => {
    const {user} = useUser();

    return (
        <div className="dashboard-container">
          <header className="dashboard-header">
            <h1>Hello, {user ? `${user.firstname} ${user.lastname}` : "User"}</h1>
            <img src={flexygig} alt="flexygig" className="dashboard-logo" />
          </header>
          
          <main className="dashboard-content">

            {/* Left Side - Main Sections */}
            <section className="dashboard-left">
              {/* <Gigs and messages go in here/> */}
            </section>
    
            {/* Right Side - Summary and Calendar */}
            <aside className="dashboard-right">

              <div className="october-summary">
                <h3>October Summary</h3>
                <p>Gigs Posted: <strong>2 gigs</strong></p>
                <p>Rating: <strong>4/5</strong></p>
              </div>
    
              <div className="calendar-section">
                <h3>Calendar</h3>
                <Calendar />
              </div>
            </aside>
          </main>
        </div>
      );
};

export default UserDashboard;