import "../styles/CalendarWidget.css";

const CalendarWidget = () => {
    // JS
    const locale = 'en-US';
    const today = new Date();
    const weekday = today.toLocaleDateString(locale, { weekday: "long" });
    const month = today.toLocaleDateString(locale, { month: "short" });

    // TODO: get upcoming events from user once set up in database

    // HTML
    return (
        <div className="CalendarWidget">
            <div className="Date">
                <div className="left">
                    <p id="day-name">{weekday}</p>
                    <p id="day-number">{today.getDate()}</p>
                </div>
                <div className="right">
                    <p id="month">{month}</p>
                    <p id="year">{today.getFullYear()}</p>
                </div>
            </div>
            <div className="Events">
                {/* Using dummy event schedule for now */}
                <p id="upcoming">Upcoming</p>
                <ul>
                    <li>
                        <p>Interview w/ Amanda Lopez</p>
                        <p>Friday 4th, 10:30A.M.</p>
                    </li>
                    <li>
                        <p>Jennifer works (6A.M - 2P.M.)</p>
                        <p>Saturday 5th, 10:30P.M.</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CalendarWidget;
