import React from "react";
import '../styles/Featured.scss';


function Featured() {
  
    return (
      
     [<div className="featured">
        <div className="container">
          <div className="left">
            <h1>
              Find the perfect <span>freelance</span> <br></br>
              <span>services</span> for your business
            </h1>
            
          </div>
        </div>
      </div>]
    );
  }
export default Featured;