import axios from "axios";
import React, { useEffect, useState } from "react";
import Toggle from "../components/Toggle";
import "../styles/ProfilePage.css";
import { useUser } from "./UserContext";

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    ...user,
    skills: user.skills || [],
  });
  const [workerEnabled, setWorkerEnabled] = useState(false);
  const [employerEnabled, setEmployerEnabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [skills, setSkills] = useState([]);

  // Fetch user profile data when the component mounts
  useEffect(() => {
    axios
      .get(`/api/profile/${user.id}`)
      .then((response) => {
        // Update state with the fetched user data
        setUser((prevUser) => ({
          ...prevUser,
          ...response.data.profileData,
          ...response.data.businessData,
        }));
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  }, [submit]);

  const fetchProfile = () => {
    axios
      .get(`/api/profile/${user.id}`)
      .then((response) => {
        let merge = {
          ...response.data.profileData,
          ...response.data.businessData,
        };
        setEditedUser(merge);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  useEffect(() => {
    axios
      .get("/api/skills")
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => {
        console.error("Error fetching skills:", error);
      });
  }, []); // empty dependency array to ensure this effect runs only once

  const handleChange = (event) => {
    const { name, value, options } = event.target;
    if (event.target.type === "select-multiple") {
      const values = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setEditedUser((prev) => ({ ...prev, [name]: values }));
    } else {
      setEditedUser((prev) => ({ ...prev, [name]: value }));
    }
  };

  const toggleEdit = () => {
    fetchProfile();
    setIsEditing(!isEditing);
  };

  const toggleWorker = () => setWorkerEnabled(!workerEnabled);
  const toggleEmployer = () => setEmployerEnabled(!employerEnabled);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    try {
      const response = await axios.post(
        `/api/profile/update/${user.id}`,
        editedUser
      );

      let merge = {
        ...user,
        ...response.data.businessData,
        ...response.data.profileData,
      };
      setIsEditing(false);
      setUser(merge); // Ensure the global user state is also updated
      setEditedUser(merge);
    } catch (error) {
      console.error("Update failed:", error.response || error);
    }
  };

  const handleSkillChange = (skillName) => {
    const skillsArray = editedUser.skills || []; // Ensure it's always an array
    const newSkills = skillsArray.includes(skillName)
      ? skillsArray.filter((skill) => skill !== skillName)
      : [...skillsArray, skillName];
    setEditedUser({ ...editedUser, skills: newSkills });
  };

  if (isEditing) {
    return (
      <div className="user-profile-form">
        <form onSubmit={handleSubmit} className="form">
          <div className="form-sections-container">
            {/* Worker Section with Toggle */}
            <div className="form-section worker-section">
              <div className="toggle-container">
                Worker{" "}
                <Toggle isEnabled={workerEnabled} onToggle={toggleWorker} />
              </div>

              {workerEnabled ? (
                <>
                  <fieldset
                    disabled={!workerEnabled}
                    className={!workerEnabled ? "disabled-section" : ""}
                  >
                    <label htmlFor="biography" className="form-label">
                      Biography:
                    </label>
                    <textarea
                      id="biography"
                      name="biography"
                      value={editedUser.biography || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="firstname" className="form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={editedUser.firstname || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="lastName" className="form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={editedUser.lastname || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="worker_phone_number" className="form-label">
                      Phone Number:
                    </label>
                    <input
                      type="text"
                      id="worker_phone_number"
                      name="worker_phone_number"
                      value={editedUser.worker_phone_number || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label
                      htmlFor="worker_street_address"
                      className="form-label"
                    >
                      Street Adress:
                    </label>
                    <input
                      type="text"
                      id="worker_street_address"
                      name="worker_street_address"
                      value={editedUser.worker_street_address || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="worker_city" className="form-label">
                      City:
                    </label>
                    <input
                      type="text"
                      id="worker_city"
                      name="worker_city"
                      value={editedUser.worker_city || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="worker_province" className="form-label">
                      Province:
                    </label>
                    <input
                      type="text"
                      id="worker_province"
                      name="worker_province"
                      value={editedUser.worker_province || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="worker_postal_code" className="form-label">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      id="worker_postal_code"
                      name="worker_postal_code"
                      value={editedUser.worker_postal_code || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="desired_work_radius" className="form-label">
                      Work Radius (km):
                    </label>
                    <input
                      type="number"
                      id="desired_work_radius"
                      name="desired_work_radius"
                      value={editedUser.desired_work_radius || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="skills" className="form-label">
                      Skills:
                    </label>
                    <div className="tags-container">
                      {skills.map((skill) => (
                        <div
                          key={skill.skill_id}
                          onClick={() => handleSkillChange(skill.skill_name)}
                          className={`tag ${
                            editedUser.skills &&
                            editedUser.skills.includes(skill.skill_name)
                              ? "selected"
                              : ""
                          }`}
                        >
                          {skill.skill_name}
                        </div>
                      ))}
                    </div>

                    <label htmlFor="desired_pay" className="form-label">
                      Desired Pay ($/hr):
                    </label>
                    <input
                      type="number"
                      id="desired_pay"
                      name="desired_pay"
                      value={editedUser.desired_pay || ""}
                      onChange={handleChange}
                      className="input-text"
                    />
                  </fieldset>
                </>
              ) : (
                // Static display of worker information
                <div>
                  {/* Display worker information statically here. For example: */}
                  <p>Biography: {editedUser.biography}</p>
                  <p>First Name: {editedUser.firstname}</p>
                  <p>Last Name: {editedUser.lastname}</p>
                  <p>Phone Number: {editedUser.worker_phone_number}</p>
                  <p>Street Adress: {editedUser.worker_street_address}</p>
                  <p>City: {editedUser.worker_city}</p>
                  <p>Province: {editedUser.worker_province}</p>
                  <p>Postal Code: {editedUser.worker_postal_code}</p>
                  <p>Work Radius: {editedUser.desired_work_radius}</p>
                  <p>Desired Pay: {editedUser.desired_pay}</p>
                </div>
              )}
            </div>

            {/* Employer Section with Toggle */}
            <div className="form-section employer-section">
              <div className="toggle-container">
                Employer{" "}
                <Toggle isEnabled={employerEnabled} onToggle={toggleEmployer} />
              </div>
              {employerEnabled ? (
                <>
                  <fieldset
                    disabled={!employerEnabled}
                    className={!employerEnabled ? "disabled-section" : ""}
                  >
                    <label htmlFor="business_name" className="form-label">
                      Business Name:
                    </label>
                    <input
                      type="text"
                      id="business_name"
                      name="business_name"
                      value={editedUser.business_name || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label
                      htmlFor="business_phone_number"
                      className="form-label"
                    >
                      Business Phone Number:
                    </label>
                    <input
                      type="text"
                      id="business_phone_number"
                      name="business_phone_number"
                      value={editedUser.business_phone_number || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="business_email" className="form-label">
                      Business Email:
                    </label>
                    <input
                      type="text"
                      id="business_email"
                      name="business_email"
                      value={editedUser.business_email || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label
                      htmlFor="business_street_address"
                      className="form-label"
                    >
                      Business Street Address:
                    </label>
                    <input
                      type="text"
                      id="business_street_address"
                      name="business_street_address"
                      value={editedUser.business_street_address || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="business_city" className="form-label">
                      Business City:
                    </label>
                    <input
                      type="text"
                      id="business_city"
                      name="business_city"
                      value={editedUser.business_city || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="business_province" className="form-label">
                      Business Province:
                    </label>
                    <input
                      type="text"
                      id="business_province"
                      name="business_province"
                      value={editedUser.business_province || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label
                      htmlFor="business_postal_code"
                      className="form-label"
                    >
                      Business Postal Code:
                    </label>
                    <input
                      type="text"
                      id="business_postal_code"
                      name="business_postal_code"
                      value={editedUser.business_postal_code || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label
                      htmlFor="business_description"
                      className="form-label"
                    >
                      Company Bio:
                    </label>
                    <textarea
                      id="business_description"
                      name="business_description"
                      value={editedUser.business_description || ""}
                      onChange={handleChange}
                      className="input-text"
                    />

                    <label htmlFor="business_website" className="form-label">
                      Company Website:
                    </label>
                    <input
                      type="url"
                      id="business_website"
                      name="business_website"
                      value={editedUser.business_website || ""}
                      onChange={handleChange}
                      className="input-text"
                    />
                  </fieldset>
                </>
              ) : (
                // Static display of employer information
                <div>
                  {/* Display employer information statically here. For example: */}
                  <p>Business Name: {editedUser.business_name}</p>
                  <p>
                    Business Phone Number: {editedUser.business_phone_number}
                  </p>
                  <p>Business Email: {editedUser.business_email}</p>
                  <p>
                    Business Street Address:{" "}
                    {editedUser.business_street_address}
                  </p>
                  <p>Business City: {editedUser.business_city}</p>
                  <p>Business Province: {editedUser.business_province}</p>
                  <p>Business Postal Code: {editedUser.business_postal_code}</p>
                  <p>Company Bio: {editedUser.business_description}</p>
                  <p>Company Website: {editedUser.business_website}</p>
                </div>
              )}
            </div>
          </div>

          <button type="submit" className="form-button">
            Save Changes
          </button>
          <button type="button" onClick={toggleEdit} className="form-button">
            Cancel
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <button onClick={toggleEdit} className="edit-button">
          Edit Profile
        </button>
      </div>

      {user.biography && (
        <div className="profile-section">
          <h2>Biography</h2>
          <p>{user.biography || ""}</p>
        </div>
      )}

      <div className="profile-section">
        <h2>Contact Information</h2>
        <p>
          <strong>First Name:</strong> {user.firstname}
        </p>
        <p>
          <strong>Last Name:</strong> {user.lastname}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        {user.worker_phone_number && (
          <p>
            <strong>Phone number:</strong> {user.worker_phone_number}
          </p>
        )}
        {user.worker_street_address && (
          <p>
            <strong>Location:</strong> {user.worker_street_address},{" "}
            {user.worker_city}, {user.worker_province},{" "}
            {user.worker_postal_code}
          </p>
        )}
      </div>

      {user.skills && (
        <div className="profile-section">
          <h2>Skills and Work Preferences</h2>
          <p>
            <strong>Work Radius:</strong> {user.desired_work_radius} km
          </p>
          <p>
            <strong>Desired Pay (CAD):</strong> ${user.desired_pay}/hr
          </p>
          <p>
            <strong>Skills:</strong> {user.skills && user.skills.join(", ")}
          </p>
        </div>
      )}

      {user.business_name && (
        <div className="profile-section">
          <h2>Business Information</h2>
          <p>
            <strong>Business name:</strong> {user.business_name}
          </p>
          <p>
            <strong>About your business:</strong> {user.business_description}
          </p>
          <p>
            <strong>Business phone:</strong> {user.business_phone_number}
          </p>
          <p>
            <strong>Business email:</strong> {user.business_email}
          </p>
          <p>
            <strong>Business address:</strong> {user.business_street_address},{" "}
            {user.business_city}, {user.business_province},{" "}
            {user.business_postal_code}
          </p>
          <p>
            <strong>Business website:</strong> {user.business_website}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
