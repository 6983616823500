import axios from "axios";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUser } from "./UserContext";

const EventCalendar = () => {
  const { user, setUser } = useUser();
  const [newEvent, setNewEvent] = useState({
    title: "Available",
    start: "",
    end: "",
  });
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [addedEvents, setAddedEvents] = useState([]);
  const [message, setMessage] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isTimeEditable, setIsTimeEditable] = useState(false);

  const locales = {
    "en-CA": require("date-fns/locale/en-CA"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const fetchUpdatedEvents = () => {
    const user_id = user.id;

    axios
      .get(`/api/my-calendar/${user_id}`)
      .then((response) => {
        const formattedEvents = response.data.map((event) => {
          const startDateTime = `${event.startdate} ${event.starttime}`;
          const endDateTime = `${event.enddate} ${event.endtime}`;

          return {
            id: event.id,
            title: "Available",
            start: new Date(startDateTime),
            end: new Date(endDateTime),
          };
        });
        setAllEvents(formattedEvents);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  };

  useEffect(() => {
    fetchUpdatedEvents();

    const pollingInterval = setInterval(fetchUpdatedEvents, 5000);
    return () => clearInterval(pollingInterval);
  }, [user.id, setAllEvents]);

  // console.log("formatted events: ", allEvents);

  // const generateTimeOptions = () => {
  const generateTimeOptions = (includeDefault = true) => {
    const times = [];
    if (includeDefault) {
      times.push("Select Time");
    }

    for (let hour = 0; hour < 24; hour++) {
      const period = hour < 12 ? "AM" : "PM";
      const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;

      const formattedTime = `${String(formattedHour).padStart(
        2,
        "0"
      )}:00 ${period}`;
      times.push(formattedTime);
    }
    return times;
  };

  const convertTo24HourFormat = (time) => {
    const [hour, minute, period] = time.split(/:| /);
    const isPM = period === "PM";
    const hour24 =
      isPM && hour !== "12" ? parseInt(hour, 10) + 12 : parseInt(hour, 10) % 12;
    const formattedHour = String(hour24).padStart(2, "0");
    return `${formattedHour}:${minute}`;
  };

  const handleAddEvent = () => {
    if (!startTime && !endTime) {
      return setMessage("Please select both start and end times.");
    }

    if (startTime === endTime) {
      return setMessage("Start and end times are the same.");
    }

    if (startTime === "12:00 AM" && endTime === "12:00 AM") {
      return setMessage("Please select a valid time range.");
    }

    // Validate if end time is not before start time
    const startTime24 = convertTo24HourFormat(startTime);
    const endTime24 = convertTo24HourFormat(endTime);

    if (
      new Date(`2000-01-01T${endTime24}:00`) <
      new Date(`2000-01-01T${startTime24}:00`)
    ) {
      return setMessage("End time cannot be before start time.");
    }

    if (
      addedEvents.some(
        (event) => event.start === newEvent.start && event.end === newEvent.end
      )
    ) {
      setMessage(
        "Selected days are already added, Please choose different days."
      );
      setTimeout(() => {
        setMessage("");
      }, 3000);
      return;
    }

    const user_id = user.id;

    axios
      .post("/api/my-calendar", {
        user_id,
        startDate: newEvent.start,
        endDate: newEvent.end,
        startTime,
        endTime,
      })
      .then((response) => {
        console.log("Event added successfully:", response.data);
        setMessage("Schedule added successfully");
        setTimeout(() => {
          setMessage("");
        }, 3000);

        fetchUpdatedEvents();
        setAddedEvents([...addedEvents, newEvent]);
        setStartTime("");
        setEndTime("");
      })
      .catch((error) => {
        console.error("Error adding event:", error);
      });
  };

  const handleDeleteSelected = () => {
    if (!selectedEvent) {
      setMessage("No event selected to delete");
      return;
    }

    const eventIdToDelete = selectedEvent.id;

    axios
      .delete(`/api/my-calendar/${eventIdToDelete}`)
      .then((response) => {
        console.log("Event deleted successfully:", response.data);
        fetchUpdatedEvents();
        setSelectedEvent(null);
        setMessage("Selected event deleted successfully");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      })
      .catch((error) => {
        console.error("Error deleting event:", error);
        setMessage("Error deleting event");
      });
  };

  const handleDateChange = (dates) => {
    setNewEvent({ start: dates[0], end: dates[1] });
    setIsTimeEditable(!!dates[0] && !!dates[1]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p>Choose your availability.</p>

        <style>
          {`
            .react-datepicker {
              position: static !important;
            }
          `}
        </style>
        <DatePicker
          placeholderText="Select Start and End Dates"
          selectsRange
          startDate={newEvent.start}
          endDate={newEvent.end}
          onChange={handleDateChange}
          minDate={new Date()}
          inline
        />
        <div style={{ marginBottom: "10px" }}>
          <label>Start Time:</label>
          <select
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            disabled={!isTimeEditable}
          >
            {generateTimeOptions().map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>End Time:</label>
          <select
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            disabled={!isTimeEditable}
          >
            {generateTimeOptions().map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>


        </div>
        <button onClick={handleAddEvent}>Add Availability</button>

        {selectedEvent && (
          <div style={{ marginLeft: "10px" }}>
            <button
              style={{
                backgroundColor: "red",
                color: "white",
              }}
              onClick={handleDeleteSelected}
            >
              Delete Selected Event
            </button>
          </div>
        )}
        {message && <p style={{ color: "green" }}>{message}</p>}
      </div>
      <div style={{ flex: 1 }}>
        <Calendar
          localizer={localizer}
          events={allEvents}
          startAccessor={(event) => new Date(event.start)}
          endAccessor={(event) => new Date(event.end)}
          style={{
            height: "80vh",
            width: "100%",
            margin: "0",
            fontSize: "19px",
          }}
          selectable
          onSelectSlot={(slotInfo) => {
            console.log("Selected slot:", slotInfo);
            setSelectedEvent(null);
          }}
          onSelectEvent={(event) => {
            console.log("Selected event:", event);
            setSelectedEvent(event);
          }}
        />
      </div>
    </div>
  );
};

export default EventCalendar;
