// Layout.jsx
import React from "react";
import Toolbar from "./Toolbar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div style={{ display: "flex" }}>
      <Toolbar /> {/* This will remain constant */}
      <div style={{ marginLeft: "80px", width: "100%", padding: "20px" }}>
        <Outlet /> {/* The main content area for pages */}
      </div>
    </div>
  );
};

export default Layout;
