import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/JobPostingForm.css";
import { useUser } from "./UserContext";

const JobPostingForm = () => {
  const { user, setUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [jobPost, setJobPost] = useState({
    jobTitle: "",
    jobType: "",
    jobDescription: "",
    hourlyRate: "",
    jobStart: "",
    jobEnd: "",
    jobStreetAddress: "",
    jobCity: "",
    jobProvince: "",
    jobPostalCode: "",
    user_id: user ? user.id : null,
  });

  const [errorMessage, setErrorMessage] = useState("");

  const formatDateTimeForInput = (dateTime) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    let formattedDate = date.toISOString().slice(0, 16);
    return formattedDate;
  };

  useEffect(() => {
    if (location.state?.job) {
      setIsEditing(true);
      setJobPost({
        ...location.state.job,
        jobTitle: location.state.job.jobtitle,
        jobType: location.state.job.jobtype,
        jobDescription: location.state.job.jobdescription,
        hourlyRate: location.state.job.hourlyrate.toString(),
        jobStreetAddress: location.state.job.streetaddress,
        jobCity: location.state.job.city,
        jobProvince: location.state.job.province,
        jobPostalCode: location.state.job.postalcode,
        jobStart: formatDateTimeForInput(location.state.job.jobstart),
        jobEnd: formatDateTimeForInput(location.state.job.jobend),
        job_id: location.state.job.job_id,
        location_id: location.state.job.location_id,
      });
    }
  }, [location.state?.job]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobPost((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const locationData = {
      streetAddress: jobPost.jobStreetAddress,
      city: jobPost.jobCity,
      province: jobPost.jobProvince,
      postalCode: jobPost.jobPostalCode,
    };

    const updatedJobData = {
      ...jobPost,
      locationData,
    };

    const apiUrl = isEditing
      ? `/api/edit-job/${jobPost.job_id}`
      : "/api/post-job";

    try {
      await axios[isEditing ? "patch" : "post"](apiUrl, updatedJobData, {
        headers: { "Content-Type": "application/json" },
      });
      //alert(`Job ${isEditing ? 'updated' : 'posted'} successfully!`);
      navigate("/my-jobs");
    } catch (error) {
      console.error("Failed to process job:", error.message);
      setErrorMessage("Failed to process job. Please try again.");
    }
  };

  return (
    <div className="job-posting-container">
      <h2>{isEditing ? "Edit Job" : "Post New Job"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="jobTitle">Job Title</label>
          <input
            type="text"
            id="jobTitle"
            name="jobTitle"
            value={jobPost.jobTitle}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="jobType">Job Type</label>
          <input
            type="text"
            id="jobType"
            name="jobType"
            value={jobPost.jobType}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="jobDescription">Job Description</label>
          <textarea
            id="jobDescription"
            name="jobDescription"
            value={jobPost.jobDescription}
            onChange={handleChange}
            // required
          />
        </div>
        <div className="job-location">
          <div className="form-group">
            <label htmlFor="jobStreetAddress">Street Address</label>
            <input
              type="text"
              id="jobStreetAddress"
              name="jobStreetAddress"
              value={jobPost.jobStreetAddress}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="jobCity">City</label>
            <input
              type="text"
              id="jobCity"
              name="jobCity"
              value={jobPost.jobCity}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="jobProvince">Province</label>
            <select
              id="jobProvince"
              name="jobProvince"
              value={jobPost.jobProvince}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Alberta">AB</option>
              <option value="British Columbia">BC</option>
              <option value="Manitoba">MB</option>
              <option value="New Brunswick">NB</option>
              <option value="Newfoundland and Labrador">NL</option>
              <option value="Nova Scotia">NS</option>
              <option value="Ontario">ON</option>
              <option value="Prince Edward Island">PE</option>
              <option value="Quebec">QC</option>
              <option value="Saskatchewan">SK</option>
              <option value="Northwest Territories">NT</option>
              <option value="Nunavut">NU</option>
              <option value="Yukon">YT</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="jobPostalCode">Postal Code</label>
            <input
              type="text"
              id="jobPostalCode"
              name="jobPostalCode"
              value={jobPost.jobPostalCode}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="job-time">
          <div className="form-group">
            <label htmlFor="hourlyRate">Hourly Rate ($)</label>
            <input
              type="number"
              id="hourlyRate"
              name="hourlyRate"
              value={jobPost.hourlyRate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="jobStart">Job Start Date & Time</label>
            <input
              type="datetime-local"
              id="jobStart"
              name="jobStart"
              value={jobPost.jobStart}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="jobEnd">Job End Date & Time</label>
            <input
              type="datetime-local"
              id="jobEnd"
              name="jobEnd"
              value={jobPost.jobEnd}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <button type="submit">{isEditing ? "Update Job" : "Post Job"}</button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default JobPostingForm;
