import React from "react";
import { useNavigate, Link} from "react-router-dom";
import Featured from "../components/Featured";
import "../styles/LandingPage.css";

const LandingPage = () => {
  const navigate = useNavigate();
  return [
    <div className="home">
      <Featured></Featured>
      <div className="features">
        <div className="container">
          <div className="item">
            <h1>The best part? Everything.</h1>
            <div className="title">
              <img src="/images/check.png" alt="check" />
              Stick to your budget
            </div>
            <p>Find the right service for every price point.</p>
            <div className="title">
              <img src="/images/check.png" alt="check" />
              Get quality work done quickly
            </div>
            <p>
              Hand your task over to a talented freelancer in minutes, get
              long-lasting results.
            </p>
            <div className="title">
              <img src="/images/check.png" alt="check" />
              Pay when you're happy
            </div>
            <p>
              Upfront quotes mean no surprises. Payments only get released when
              you approve.
            </p>
            <div className="title">
              <img src="/images/check.png" alt="check" />
              Count on 24/7 support
            </div>
            <p>
              Our round-the-clock support team is available to help anytime,
              anywhere.
            </p>
          </div>
          <div
            className="item"
            style={{
              textAlign: "center",
              background: "transparent",
              marginTop: "20px",
            }}
          >
            <p style={{ fontSize: "30px", color: "black" }}>
              Join the community today!
            </p>
            <Link to="/signin" style={{ fontSize: "20px", color: "blue" }}>
              Sign In
            </Link>

          </div>
        </div>
      </div>
      <div className="explore">
        <div className="container">
          <h1>You need it, we've got it</h1>
          <div className="items">
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Graphics & Design`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/graphics-design.d32a2f8.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Graphics & Design</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Digital Marketing`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/online-marketing.74e221b.svg"
                alt=""
              />
              <div className="line"></div>

              <span>Digital Marketing</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Writing & Translation`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/writing-translation.32ebe2e.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Writing & Translation</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Writing & Translation`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/video-animation.f0d9d71.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Video & Animation</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Music & Audio`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/music-audio.320af20.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Music & Audio</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Programming & Tech`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/programming.9362366.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Programming & Tech</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Business`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/business.bbdf319.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Business</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Lifestyle`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/lifestyle.745b575.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Lifestyle</span>
            </div>
            <div className="item" onClick={(e) => navigate(`gigs?Data`)}>
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/data.718910f.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Data</span>
            </div>
            <div
              className="item"
              onClick={(e) => navigate(`gigs?cat=Photography`)}
            >
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/@fiverr/logged_out_homepage_perseus/apps/photography.01cf943.svg"
                alt=""
              />
              <div className="line"></div>
              <span>Photography</span>
            </div>
          </div>
        </div>
      </div>
      <div className="features dark">
        <div className="container">
          <div className="item">
            <img src="images/business-desktop-870-x1.webp" alt="imagea" />
          </div>
        </div>
      </div>

      <div className="last_hero">
        <div className="items">
          <div className="left">
            <h1>
              Suddenly it's all so{" "}
              <em>
                <span className="last_hero_em">doable.</span>
              </em>
            </h1>
            <button onClick={(e) => navigate(`/register`)}>
              Join FlexyGig
            </button>
          </div>
          <div className="right">
            <img src="/images/last_hero.webp" alt="" />
          </div>
        </div>
      </div>
    </div>,
  ];
};
export default LandingPage;
