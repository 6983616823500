import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/SignIn.css";
import { useUser } from "./UserContext";

const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInData({ ...signInData, [name]: value });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    axios
      .post("/api/login", signInData)
      .then((response) => {
        // Check if the user is active
        if (
          response.data.success === false ||
          response.data.message ===
            "Account not activated. Please check your email for verification."
        ) {
          setErrorMessage(response.data.message);
        } else {
          localStorage.setItem("user", JSON.stringify(response.data)); // Persist user data in localStorage
          setUser(response.data); // Update global user state
          navigate("/user-dashboard"); // Navigate to home page 
          
          // Changed this from /home to /user-dashboard!!!!! FIX THIS
        }
      })
      .catch((error) => {
        setErrorMessage(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Invalid credentials. Please try again."
        );
      });
  };

  return (
    <div className="center-container">
      <h2>Flexygig</h2>
      <h2>Sign In</h2>
      <form onSubmit={handleSignIn}>
        <div className="form-group">
          <label htmlFor="signInEmail">Email:</label>
          <input
            type="email"
            id="signInEmail"
            name="email"
            value={signInData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="signInPassword">Password:</label>
          <input
            type="password"
            id="signInPassword"
            name="password"
            value={signInData.password}
            onChange={handleChange}
            required
          />
          <Link
            to="/initiate-password-reset"
            style={{
              color: "#4CAF50",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Forgot password?
          </Link>
        </div>

        <button type="submit">Sign In</button>
        <p>
          Don't have an account?{" "}
          <Link
            to="/register"
            style={{
              color: "#4CAF50",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Register
          </Link>
        </p>
      </form>

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default SignIn;
